import React, { useState } from 'react'
/// React router dom
import {Switch, Route } from 'react-router-dom'
/// Css
import './index.css'
import './chart.css'
import './step.css'

/// Layout
import Nav from './layouts/nav'
import Footer from './layouts/Footer'

/// Dashboard
import Home from "./components/Dashboard/Home";
import MyProfile from "./components/Dashboard/MyProfile";
import Member from "./components/Dashboard/Member";
import History from "./components/Dashboard/History";
import WalletRecord from "./components/Dashboard/WalletRecord";
import Packs from "./components/Dashboard/Packs";
import Resellers from "./components/Dashboard/Resellers";
import Feedback from "./components/Dashboard/Feedback";
import ResellerDetail from "./components/Dashboard/ResellerDetails";

import Vouchers from './components/Dashboard/Vouchers';
import VoucherCodes from './components/Dashboard/VoucherCodes';

import OrderStatus from './components/Dashboard/OrderStatus';

import Setting from './components/Dashboard/Setting';
import LicenseKeys from './components/Dashboard/LicenseKeys';
import Products from './components/Dashboard/Products';

/// Pages
import Registration from './pages/Registration'
import Login from './pages/Login'
import ForgotPassword from './pages/forgot-password/forgot-password'
import LockScreen from './pages/LockScreen'
import Error400 from './pages/Error400'
import Error403 from './pages/Error403'
import Error404 from './pages/Error404'
import Error500 from './pages/Error500'
import Error503 from './pages/Error503'

//Scroll To Top
import ScrollToTop from './layouts/ScrollToTop';
import { Alert } from 'react-bootstrap'


const Markup = () => {
  let path = window.location.pathname
  path = path.split('/')
  path = path[path.length - 1]
  let pagePath = path.split('-').includes('page')
  const [activeEvent, setActiveEvent] = useState(!path)

  const routes = [
    /// Dashboard
    { url: '', component: Home },
    { url: 'dashboard', component: Home },
    { url: "my-profile", component: MyProfile },
    { url: "members", component: Member },
    { url: "history", component: History },
    { url: "wallet-record", component: WalletRecord },
    { url: 'license-keys/:filter', component: LicenseKeys },
    { url: "packs", component: Packs },
    { url: "resellers", component: Resellers },
    { url: 'reseller/:id', component: ResellerDetail },
    { url: "feedback", component: Feedback },
    { url: 'vouchers', component: Vouchers },
    { url: 'voucher-codes/:id', component: VoucherCodes },
    { url: "setting", component: Setting },
    { url: "products", component: Products },

    { url: "order-status/:id", component: OrderStatus },




    /// pages
    { url: 'page-register', component: Registration },
    { url: 'page-lock-screen', component: LockScreen },
    { url: 'page-login', component: Login },
    { url: 'forgot-password', component: ForgotPassword },
    { url: 'page-error-400', component: Error400 },
    { url: 'page-error-403', component: Error403 },
    { url: 'page-error-404', component: Error404 },
    { url: 'page-error-500', component: Error500 },
    { url: 'page-error-503', component: Error503 },
  ]

  return (
       <> 
          <div
            id={`${!pagePath ? 'main-wrapper' : ''}`}
            className={`${!pagePath ? 'show' : 'mh100vh'}`}
          >
            {!pagePath && (
              <Nav
                onClick={() => setActiveEvent(!activeEvent)}
                activeEvent={activeEvent}
                onClick2={() => setActiveEvent(false)}
                onClick3={() => setActiveEvent(true)}
              />
            )}
            <div
              className={` ${!path && activeEvent ? 'rightside-event' : ''} ${
                !pagePath ? 'content-body' : ''
              }`}
            >
              <div
                className={`${!pagePath ? 'container-fluid' : ''}`}
                style={{ minHeight: window.screen.height - 60 }}
              >

<Alert variant="warning">
             Our website panel will undergo maintenance at 12:00 PM IST on 25th Jan 2025 and will be temporarily unavailable. The software will continue to work well. We appreciate your understanding as we enhance our services. Apologies for any inconvenience.
              </Alert>

                <Switch>
                  {routes.map((data, i) => (
                    <Route
                      key={i}
                      exact
                      path={`/${data.url}`}
                      component={data.component}
                    />
                  ))}
                </Switch>
              </div>
            </div>
            {!pagePath && <Footer />}
          </div>
         <ScrollToTop />
       </>
  )
}

export default Markup
